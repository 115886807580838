<script lang="ts">import FormFieldGroup from "../FormFieldGroup.svelte";
import NumberRange from "./NumberRange.svelte";
import DecimalRange from "./DecimalRange.svelte";
import DateTimeRange from "./DateTimeRange.svelte";
import DateTimepicker from "./DateTimepicker.svelte";
import Datepicker from "./Datepicker.svelte";
import DateRange from "./DateRange.svelte";
import Timepicker from "./Timepicker.svelte";
import JsonTasks from "./JsonTasks.svelte";
import Select from "./Select.svelte";
import Toggle from "./Toggle.svelte";
import QrCode from "./QrCode.svelte";
import { isDecimal, isNumber } from "./helpers";
export let error = undefined;
export let value = null;
export let fieldData;
export let onSave = null;
export let hideLabel = false;
export let passClass = "";
let saving = false;
const revertValue = () => (value = JSON.parse(JSON.stringify({ initialValue }))["initialValue"]);
const saveValue = async () => {
    saving = true;
    //Disable Field
    fieldData.disabled = true;
    let saveSuccessful;
    try {
        saveSuccessful = await onSave();
    }
    catch (error) {
        saving = false;
        fieldData.disabled = false;
        throw error;
    }
    if (saveSuccessful)
        initialValue = JSON.parse(JSON.stringify({ value }))["value"];
    saving = false;
    fieldData.disabled = false;
};
let isUnsavedUpdates = false;
let initialValue;
$: roundRightonFocus = !onSave;
$: if (initialValue === undefined) {
    initialValue = JSON.parse(JSON.stringify({ value }))["value"];
}
$: (isUnsavedUpdates = JSON.stringify({ value }) != JSON.stringify({ value: initialValue })), initialValue, value;
$: (error = undefined), value;
</script>

<div class="{passClass} formfield w-full {fieldData.width == 'full' ? 'md:w-full' : 'md:w-1/2'}">
  <div class="flex flex-row space-x-2 w-full">
    {#if !hideLabel}
      <label
        for="forminput-{fieldData.label}"
        class="block text-sm font-medium leading-5 text-secondary-900 dark:text-gray-200  py-2 mb-auto {fieldData.width == 'full' ? '' : 'w-1/3'}"
        style={fieldData.width == 'full' ? 'width: 16.2%;' : 'width: 33.333333%;'}
      >
        {fieldData.label}:
      </label>
    {/if}
    <div
      class="{hideLabel ? 'w-full' : 'my-0.5'} flex rounded-md  {fieldData.type == 'toggle' && !isUnsavedUpdates ? '' : 'shadow-sm'}"
      style={hideLabel ? '' : fieldData.width == 'full' ? 'width: 83.8%;' : 'width: 66.666667%;'}
    >
      <div class="relative h-full flex-grow focus-within:z-10">
        {#if fieldData.type == 'text' || !fieldData.type}
          <input
            bind:value
            type="text"
            id="forminput-{fieldData.label}"
            disabled={!fieldData.field || !!fieldData.disabled}
            placeholder={fieldData.field ? fieldData.placeholder : 'No field set'}
            required={fieldData.required ?? true}
            class="form-input block w-full h-full {!fieldData.field || !!fieldData.disabled ? 'bg-gray-200' : ''}
            {isUnsavedUpdates && !roundRightonFocus ? 'rounded-r-none' : ''} sm:text-sm sm:leading-5 transition ease-in-out duration-150"
          />
        {:else if fieldData.type == 'number'}
          <input
            bind:value
            on:keypress={isNumber}
            on:paste={isNumber}
            id="forminput-{fieldData.label}"
            disabled={!fieldData.field || !!fieldData.disabled}
            placeholder={fieldData.field ? fieldData.placeholder : 'No field set'}
            required={fieldData.required ?? true}
            type="number"
            class="number-input form-input block w-full h-full {!fieldData.field || !!fieldData.disabled ? 'bg-gray-200' : ''}
            {isUnsavedUpdates && !roundRightonFocus ? 'rounded-r-none' : ''} sm:text-sm sm:leading-5 transition ease-in-out duration-150"
          />
        {:else if fieldData.type == 'decimal'}
          <input
            bind:value
            on:keypress={isDecimal}
            on:paste={isDecimal}
            id="forminput-{fieldData.label}"
            disabled={!fieldData.field || !!fieldData.disabled}
            placeholder={fieldData.field ? fieldData.placeholder : 'No field set'}
            required={fieldData.required ?? true}
            type="number"
            class="number-input form-input block w-full h-full {!fieldData.field || !!fieldData.disabled ? 'bg-gray-200' : ''}
            {isUnsavedUpdates && !roundRightonFocus ? 'rounded-r-none' : ''} sm:text-sm sm:leading-5 transition ease-in-out duration-150"
          />
        {:else if fieldData.type == 'decimalrange'}
          <DecimalRange
            bind:value
            id="forminput-{fieldData.label}"
            {isUnsavedUpdates}
            {roundRightonFocus}
            disabled={!!fieldData.disabled}
            required={fieldData.required ?? true}
          />
        {:else if fieldData.type == 'numberrange'}
          <NumberRange
            bind:value
            id="forminput-{fieldData.label}"
            {isUnsavedUpdates}
            {roundRightonFocus}
            disabled={!!fieldData.disabled}
            required={fieldData.required ?? true}
          />
        {:else if fieldData.type == 'bigtext'}
          <textarea
            bind:value
            id="forminput-{fieldData.label}"
            disabled={!fieldData.field || !!fieldData.disabled}
            placeholder={fieldData.field ? fieldData.placeholder : 'No field set'}
            required={fieldData.required ?? true}
            class="form-input customscrollbar block w-full h-full {!fieldData.field || !!fieldData.disabled ? 'bg-gray-200' : ''}
            {isUnsavedUpdates && !roundRightonFocus ? 'rounded-r-none' : ''} sm:text-sm sm:leading-5 transition ease-in-out duration-150"
          />
        {:else if fieldData.type == 'datetime'}
          <DateTimepicker bind:value id="forminput-{fieldData.label}" {roundRightonFocus} closeOnSelect={!onSave} required={fieldData.required ?? true} />
        {:else if fieldData.type == 'datetimerange'}
          <DateTimeRange
            bind:value
            id="forminput-{fieldData.label}"
            {isUnsavedUpdates}
            {roundRightonFocus}
            closeOnSelect={!onSave}
            disabled={!!fieldData.disabled}
            required={fieldData.required ?? true}
          />
        {:else if fieldData.type == 'date'}
          <Datepicker bind:value id="forminput-{fieldData.label}" {roundRightonFocus} closeOnSelect={!onSave} required={fieldData.required ?? true} />
        {:else if fieldData.type == 'daterange'}
          <DateRange
            bind:value
            id="forminput-{fieldData.label}"
            {isUnsavedUpdates}
            {roundRightonFocus}
            closeOnSelect={!onSave}
            disabled={!!fieldData.disabled}
            required={fieldData.required ?? true}
          />
        {:else if fieldData.type == 'time'}
          <Timepicker bind:value id="forminput-{fieldData.label}" {roundRightonFocus} required={fieldData.required ?? true} />
        {:else if fieldData.type == 'select'}
          <Select
            bind:value
            id="forminput-{fieldData.label}"
            {isUnsavedUpdates}
            {roundRightonFocus}
            {fieldData}
            disabled={!!fieldData.disabled}
            placeholder={fieldData.field ? fieldData.placeholder : 'No field set'}
            required={fieldData.required ?? true}
          />
        {:else if fieldData.type == 'jsontasks'}
          <JsonTasks
            bind:value
            id="forminput-{fieldData.label}"
            {roundRightonFocus}
            {fieldData}
            disabled={!!fieldData.disabled}
            required={fieldData.required ?? true}
          />
        {:else if fieldData.type == 'toggle'}
          <Toggle
            bind:value
            id="forminput-{fieldData.label}"
            {isUnsavedUpdates}
            {roundRightonFocus}
            disabled={!!fieldData.disabled}
            required={fieldData.required ?? true}
          />
        {:else if fieldData.type == 'subform'}
          <div class="form-input flex-col space-y-2">
            <FormFieldGroup bind:value fields={fieldData?.fields} fieldsQuery={fieldData?.fieldsQuery} />
          </div>
        {:else if fieldData.type == 'qrcode'}
          <QrCode bind:value />
        {/if}
      </div>
      {#if error}
        <span
          class="-ml-px relative inline-flex items-center px-4 py-1 border border-gray-300 text-sm leading-5 font-medium
          text-red-700 bg-gray-50 focus:outline-none transition ease-in-out duration-150"
        >
          {error}
        </span>
      {/if}
      {#if onSave && isUnsavedUpdates}
        <button class="revert dark:text-gray-200 bg-gray-50 hover:text-gray-500 hover:bg-white dark:bg-gray-600  focus:outline-none active:bg-white
        active:text-gray-700" on:click={revertValue} type="button"> <span>X</span> </button>
        <button class="save bg-gray-50 hover:text-gray-500 hover:bg-white  focus:outline-none active:bg-white
        active:text-gray-700" on:click={saveValue} type="button"> <span class={saving ? 'text-primary-600' : ''}>{saving ? 'Saving' : 'Save'}</span> </button>
      {/if}
    </div>
  </div>
</div>

<style>
  .number-input {
    -moz-appearance: textfield;
  }
  .number-input::-webkit-inner-spin-button {
    display: none;
  }
  .number-input::-webkit-outer-spin-button,
  .number-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  :global(.formfield button.revert), :global(.formfield button.save) {
    position: relative;
    margin-left: -1px;
    display: inline-flex;
    align-items: center;
    border-width: 1px;
    border-color: var(--primary-600);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    line-height: 1.25rem;
    --tw-text-opacity: 1;
    color: rgba(35, 35, 41, var(--tw-text-opacity));
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    --tw-ring-color: var(--primary-600);
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transition-duration: 150ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

  :global(.dark .formfield button.revert), :global(.dark .formfield button.save) {
    --tw-border-opacity: 1;
    border-color: rgba(203, 203, 205, var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgba(42, 42, 49, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(203, 203, 205, var(--tw-text-opacity));
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(203, 203, 205, var(--tw-ring-opacity));
}</style>
