<script>import { rx, matchState } from "../rxfsm";
import { Provider } from "../provider";
import Loader from "../General/Loader.svelte";
let self, loggedOutState, authStateMain, authContext, loggedOutSender, authSender, currentError;
$: if (self)
    [authStateMain, authContext, authSender] = Provider.of("authState", self);
$: if (authStateMain)
    loggedOutState = authStateMain.pipe(rx.pluck("child"), rx.pluck("current"));
$: if (authStateMain)
    loggedOutSender = authSender.child;
$: if (authStateMain)
    currentError = $authContext.error;
$: authTitle = (() => {
    switch ($loggedOutState) {
        case "signinform":
            return "Sign in to your Account";
        case "sendforgotpasswordform":
        case "didforgotpasswordform":
        case "accountmanagement":
            return "Reset Your Password";
        case "checking":
            return "Loading...";
        case "signingin":
            return "Signing In...";
        case "handlingresetpassword":
            return "Resetting Password...";
        case "sendingforgotpassword":
            return "Sending Forgot Password...";
    }
})();
$: buttonTitle = (() => {
    switch ($loggedOutState) {
        case "signinform":
            return "Sign In";
        case "sendforgotpasswordform":
            return "Send Password Reset Email";
        case "didforgotpasswordform":
            return "Sent Email!";
        case "accountmanagement":
            return "Submit New passowrd";
    }
})();
function formHandler() {
    const fd = new FormData(this);
    const values = Object.fromEntries(fd.entries());
    switch ($loggedOutState) {
        case "signinform":
            loggedOutSender["signin"](values);
            break;
        case "sendforgotpasswordform":
            loggedOutSender["sendforgotpassword"](values);
            break;
        case "accountmanagement":
            if (values.newpassword === values.confirmnewpassword)
                loggedOutSender["sendresetpassword"]({ password: values.newpassword });
            else
                currentError = "Error: Passwords do not match";
            break;
    }
}
</script>

<div bind:this={self} class="min-h-screen bg-unit-secondary-500 text-secondary-800 flex flex-col justify-center w-full py-12 sm:px-6 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <img class="mx-auto h-12 w-auto" src="img/logo.svg" alt="Logo" />
    <h2 class="mt-6 text-center text-3xl leading-9 font-extrabold text-white">{authTitle}</h2>
  </div>

  <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md mx-8">
    <div class="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
      {#if matchState($loggedOutState, 'checking', 'signingin', 'handlingresetpassword', 'sendingforgotpassword')}
        <Loader />
      {:else if matchState($loggedOutState, 'signinform', 'sendforgotpasswordform', 'didforgotpasswordform', 'accountmanagement')}
        <form on:submit|preventDefault={formHandler} class="space-y-6">
          {#if matchState($loggedOutState, 'signinform', 'sendforgotpasswordform')}
            <label class="space-y-1"> <span class="font-medium"> Email address </span> <input name="email" type="email" required /> </label>
          {/if}
          {#if $loggedOutState === 'signinform'}
            <label class="space-y-1"> <span class="font-medium"> Password </span> <input name="password" type="password" required /> </label>
          {/if}
          {#if $loggedOutState === 'accountmanagement'}
            <label class="space-y-1"> <span class="font-medium"> New Password </span> <input name="newpassword" type="password" required /> </label>
            <label class="space-y-1">
              <span class="font-medium"> Confirm New Password </span>
              <input name="confirmnewpassword" type="password" required />
            </label>
          {/if}

          {#if $loggedOutState === 'signinform'}
            <div class="flex items-center justify-between">
              <label class="flex flex-row items-center space-x-2">
                <input name="rememberme" type="checkbox" class="form-checkbox h-4 w-4 text-unit-primary-600 transition duration-150 ease-in-out" />
                <span> Remember me </span>
              </label>

              <div class="text-sm leading-5">
                <button
                  on:click|preventDefault={loggedOutSender['toggleforgotpasswordform']}
                  type="button"
                  class="font-medium text-unit-primary-600 hover:text-unit-primary-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                >
                  Forgot your password?
                </button>
              </div>
            </div>
          {/if}

          {#if $loggedOutState === 'didforgotpasswordform'}
            The email with further instructions was sent to the submitted email address. If you don’t receive a message in 5 minutes, check the junk folder. If
            you are still experiencing any problems, contact support at
            <a href="mailto:support@unitinnovations.com">support@unitinnovations.com</a>
          {/if}

          <span class="block w-full rounded-md shadow-sm">
            <button
              type="submit"
              class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-unit-secondary-500 hover:bg-unit-primary-500 focus:outline-none focus:border-unit-primary-700 focus:shadow-outline-unit-primary active:bg-unit-primary-700 transition duration-150 ease-in-out"
            >
              {buttonTitle}
            </button>
          </span>

          {#if matchState($loggedOutState, 'sendforgotpasswordform', 'didforgotpasswordform')}
            <div class="flex items-center justify-between">
              <div class="text-sm leading-5 mx-auto">
                <button
                  on:click|preventDefault={loggedOutSender['toggleforgotpasswordform']}
                  type="button"
                  class="font-medium text-unit-primary-600 hover:text-unit-primary-500 focus:outline-none focus:underline transition ease-in-out duration-150"
                >
                  Go Back to Sign In
                </button>
              </div>
            </div>
          {/if}
          {#if currentError}
            <div class="my-3 flex"><span class="text-red-600 text-sm text-center mx-auto">{currentError}</span></div>
          {/if}

          <p class="text-xs">
            This is a private computer system. Notice to all users, this computer system is the property of UNIT Innovations (the "Company"). This computer
            system, including all related equipment, networks, and devices (specifically including internet access), are provided only for authorized users of
            clients who have executed a Software-as-a-Service Agreement (the "Agreement") with the Company. By continuing to use this system, you indicate your
            awareness of and consent to the terms and conditions of the Agreement.
          </p>
        </form>
      {/if}
    </div>
  </div>
</div>

<style>
  label {

    display: block;

    font-size: 0.875rem;

    line-height: 1.25rem;

    line-height: 1.25rem;

    --tw-text-opacity: 1;

    color: rgba(8, 9, 10, var(--tw-text-opacity))
}

  label.flex {

    display: flex
}

  input:not([type="checkbox"]) {

    display: block;

    width: 100%;

    appearance: none;

    border-radius: 0.18rem;

    border-width: 1px;

    --tw-border-opacity: 1;

    border-color: rgba(164, 165, 167, var(--tw-border-opacity));

    padding-top: 0.5rem;

    padding-bottom: 0.5rem;

    padding-left: 0.75rem;

    padding-right: 0.75rem;

    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 150ms;

    transition-duration: 150ms;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

  @media (min-width: 640px) {
    input {

        font-size: 0.875rem;

        line-height: 1.25rem
    }
    input {

        line-height: 1.25rem
    }
  }

  input:focus {

    --tw-border-opacity: 1;

    border-color: rgba(147, 197, 253, var(--tw-border-opacity));

    --tw-shadow: 0 0 0 3px rgba(191, 219, 254, .5);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

    outline: 2px solid transparent;

    outline-offset: 0
}

  input::placeholder {

    --tw-text-opacity: 1;

    color: rgba(96, 97, 100, var(--tw-text-opacity))
}</style>
