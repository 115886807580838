<script lang="ts">var _a;
import { onMount } from "svelte";
import { queryClient } from "@src/GraphQL/query";
import Loader from "../General/Loader.svelte";
import { flattenData } from "../random";
export let options;
const gridSize = (_a = options.gridSize) !== null && _a !== void 0 ? _a : 3;
let loading = true;
let statsUI = [];
onMount(async () => {
    for (let stat of options.stats) {
        let data = (await queryClient({ query: `${stat.query} ` })).data;
        let dataFirstArrVal = Object.values(data !== null && data !== void 0 ? data : {})[0];
        let flatData = flattenData(Array.isArray(dataFirstArrVal) ? dataFirstArrVal : data).data;
        statsUI.push({
            name: stat.name,
            statValue: flatData.statValue,
            icon: stat.icon,
        });
    }
    loading = false;
});
</script>

{#if !loading}
  <div>
    <div class="grid grid-cols-1 gap-5 sm:grid-cols-1 lg:grid-cols-{gridSize} mb-5">
      {#each statsUI as stat}
        <div class="bg-white dark:bg-gray-800  overflow-hidden rounded-lg border border-gray-400 dark:border-gray-900">
          <div class="px-3 py-3">
            <div class="flex items-center">
              <div class="flex-shrink-0 bg-primary-600 rounded-md p-3"><img class="h-6 w-6 icon-white" src="/icons/{stat.icon}.svg" alt="Icon" /></div>
              <div class="ml-3 w-0 flex-1">
                <dl>
                  <dt class="text-sm leading-5 font-medium text-gray-500 dark:text-gray-200 truncate">{stat.name}</dt>
                  <dd class="flex items-baseline">
                    <div class="{stat.statValue.length < 5 ? 'text-2xl' : 'text-xl'} leading-8 font-semibold text-primary-600">{stat.statValue}</div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      {:else}
        <h3 class="py-4 pl-4">No Charts Found</h3>
      {/each}
    </div>
  </div>
{:else}
  <div class="grid grid-cols-1 md:grid-cols-{gridSize} gap-5 mb-5">
    {#each options.stats as stat}
      <div class="bg-white dark:bg-gray-800  overflow-hidden rounded-lg border border-gray-400 dark:border-gray-900">
        <div class="px-3 py-3">
          <div class="flex items-center">
            <div class="flex-shrink-0 bg-primary-600 rounded-md p-3">
              <div class="h-6 w-6">
                <Loader svgClasses="h-6 w-6 text-white" classes="" />
              </div>
            </div>
            <div class="ml-3 w-0 flex-1">
              <dl>
                <dt class="text-sm leading-5 font-medium text-gray-500 dark:text-gray-200 truncate">Stat Loading</dt>
              </dl>
            </div>
          </div>
        </div>
      </div>
    {:else}
      <h3 class="py-4 pl-4">No Stats Found</h3>
    {/each}
  </div>
{/if}

<style>
  .icon-white {
    filter: brightness(0) invert(1);
  }</style>
