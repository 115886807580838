<script lang="ts">var _a;
import FormField from "../Components/Form/FormField.svelte";
import Loader from "../General/Loader.svelte";
import { queryClient } from "../GraphQL/query";
import { mutateClient } from "../GraphQL/mutate";
import { getQueryVariableNames } from "../GraphQL/helpers";
import { slideOverOpts } from "../stores";
import { flattenData, stringSepToNorm } from "../random";
export let modalDiv = null;
$: if (((_a = fieldContainer === null || fieldContainer === void 0 ? void 0 : fieldContainer.childNodes) !== null && _a !== void 0 ? _a : []).length == 0)
    (fieldValues = {}), fieldValues;
const closePanel = () => {
    $slideOverOpts = null;
    fieldValues = {};
};
//Same exact thing as in tableactions
const getVariablesSelectedRow = (mutationVariableNames) => {
    let variablesVals = {};
    mutationVariableNames.forEach((varName) => {
        const splitVarName = varName.split("_");
        if (splitVarName[0] == "selectedRow") {
            let valueInContext;
            splitVarName.forEach((varPart, index) => {
                if (index != 0) {
                    valueInContext = valueInContext ? valueInContext[varPart] : $slideOverOpts.selectedRow[varPart];
                }
            });
            variablesVals[varName] = valueInContext;
        }
    });
    return variablesVals;
};
let saving = false;
const saveData = async () => {
    if ($slideOverOpts) {
        saving = true;
        const mutationVariableNames = getQueryVariableNames($slideOverOpts.mutation);
        const variables = {
            ...Object.fromEntries(Object.entries(fieldValues).map(([field, value]) => [`param_${field}`, value])),
            ...getVariablesSelectedRow(mutationVariableNames),
        };
        console.log({
            mutation: $slideOverOpts.mutation,
            variables,
        });
        try {
            await mutateClient({
                mutation: $slideOverOpts.mutation,
                mutationVarMappings: $slideOverOpts.mutationVarMappings,
                variables,
            });
        }
        catch (error) {
            saving = false;
            throw error;
        }
        fieldValues = {};
        $slideOverOpts.actionCallback();
        saving = false;
        closePanel();
    }
};
const handleSubmit = async (event) => {
    saveData();
};
function clickOutside(node) {
    const isNestedChild = (parent, child) => {
        let currentNode = child;
        while (currentNode !== null) {
            if (currentNode.parentNode === parent) {
                return true;
            }
            else {
                currentNode = currentNode.parentNode;
            }
        }
        return false;
    };
    const handleClick = (event) => {
        var _a;
        if ($slideOverOpts) {
            const { target } = event;
            if (!node.isSameNode(target) &&
                !isNestedChild(node, target) &&
                !node.isSameNode(modalDiv) &&
                !isNestedChild(modalDiv, target) &&
                !((_a = $slideOverOpts.exclusions) === null || _a === void 0 ? void 0 : _a.includes(target.id)) &&
                document.body.contains(target)) {
                console.log({ target, node, modalDiv });
                closePanel();
            }
        }
    };
    document.addEventListener("click", handleClick);
    return {
        destroy() {
            document.removeEventListener("click", handleClick);
        },
    };
}
let fieldContainer;
let fieldValues = {};
$: if (($slideOverOpts === null || $slideOverOpts === void 0 ? void 0 : $slideOverOpts.query) && Object.keys(fieldValues).length == 0) {
    const variables = getVariablesSelectedRow(getQueryVariableNames($slideOverOpts.query));
    queryClient({ query: $slideOverOpts.query, variables }).then(({ data }) => {
        fieldValues = Object.fromEntries(Object.entries(data.data)
            .filter(([k, v]) => k != "__typename")
            .map(([k, v]) => {
            return [k, Array.isArray(v) ? v.map((en) => flattenData(en)["data"]["key"]) : v];
        }));
    });
}
</script>

<div style={$slideOverOpts ? 'width: 100vw;' : 'width: 0px;'} use:clickOutside class="sidenav">
  <section class="absolute inset-y-0 pl-16 max-w-full right-0 flex">
    <div class="w-screen max-w-md">
      <form
        on:keydown={(e) => e.key === 'Enter' && (e.preventDefault(), handleSubmit(e))}
        on:submit|preventDefault={handleSubmit}
        class="h-full divide-y divide-secondary-200 flex flex-col bg-white dark:bg-gray-800  shadow-xl"
        autocomplete="off"
      >
        <div class="flex-1 h-0 overflow-y-auto customscrollbar">
          <header class="space-y-1 py-6 px-4 bg-primary-700 sm:px-6">
            <div class="flex items-center justify-between space-x-3">
              <h2 class="text-lg leading-7 font-medium text-white">
                {#if $slideOverOpts}
                  {#if $slideOverOpts.action == 'new'}Add to{:else if $slideOverOpts.action == 'edit'}Edit item in{/if}
                  {stringSepToNorm($slideOverOpts.tableName, '_')}
                {:else}Loading...{/if}
              </h2>
              <div class="h-7 flex items-center">
                <button
                  on:click={closePanel}
                  aria-label="Close panel"
                  class="text-primary-200 hover:text-white transition ease-in-out
                  duration-150"
                >
                  <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
            <div>
              <p class="text-sm leading-5 text-primary-300">Fill in the information below and click Save.</p>
            </div>
          </header>
          <div class="flex-1 flex flex-col justify-between" style="grid-area: 1 / 1 / 1 / 1">
            <div class="px-4 divide-y divide-secondary-200 sm:px-6">
              <div class="space-y-6 pt-6 pb-5" bind:this={fieldContainer}>
                {#each $slideOverOpts?.fields ?? [] as field}
                  <div class="space-y-1">
                    <label for="slideinput-{field.field}" class="block text-sm font-medium leading-5 text-secondary-900 dark:text-gray-200">
                      {field.label ?? stringSepToNorm(field.field, '_')}
                    </label>
                    <div class="relative rounded-md shadow-sm">
                      <FormField bind:value={fieldValues[field.field]} fieldData={{ ...field, width: 'full' }} hideLabel />
                    </div>
                  </div>
                {/each}
              </div>
            </div>
          </div>
        </div>
        <div class="flex-shrink-0 px-4 py-4 space-x-4 flex justify-end">
          <span class="inline-flex rounded-md shadow-sm">
            <button
              type="button"
              on:click={closePanel}
              class="slideoverButton border-secondary-300 text-secondary-700 hover:text-secondary-500 dark:text-secondary-200 dark:hover:text-secondary-300
              focus:outline-none
              active:bg-secondary-50 dark:active:bg-gray-600 active:text-secondary-800 dark:text-gray-200 dark:active:text-secondary-400"
            >
              Cancel
            </button>
          </span>
          <span class="inline-flex rounded-md shadow-sm">
            {#if !saving}
              <button
                type="submit"
                class="slideoverButton border-transparent 
                text-white bg-primary-600 hover:bg-primary-500 focus:outline-none
                focus:border-primary-700 focus:shadow-outline-primary
                active:bg-primary-700"
              >
                Save
              </button>
            {:else}
              <button
                type="submit"
                disabled
                class="opacity-75 slideoverButton border-transparent 
                text-white bg-primary-600 hover:bg-primary-500 focus:outline-none
                focus:border-primary-700 focus:shadow-outline-primary
                active:bg-primary-700"
              >
                Saving...
              </button>
            {/if}
          </span>
        </div>
      </form>
    </div>
  </section>
</div>

<style>
  .sidenav {
    height: 100%;
    width: 0;
    max-width: 28rem;
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    overflow-x: hidden;
    transition: 0.5s;
  }

  .slideoverButton {
    border-radius: 0.18rem;
    border-width: 1px;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    line-height: 1.25rem;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transition-duration: 150ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

  :global(.dark .sidenav .customscrollbar) {
    --scrollbarBG: #1c1c20;
  }</style>
