<script lang="ts">var _a, _b;
import { stringSepToNorm, flattenData } from "../random";
import { rx } from "../rxfsm";
import auth from "../auth";
const userRoles = auth.claims.pipe(rx.filter((val) => val != null), rx.pluck("https://hasura.io/jwt/claims"), rx.pluck("x-hasura-allowed-roles"));
import { QueryStore } from "../GraphQL/query";
import { getQueryFirstSelectionName, getQueryFirstSelection, getSourceSelections, getQueryVariableType } from "../GraphQL/helpers";
import GeneralHeading from "../General/GeneralHeading.svelte";
import FormField from "./Form/FormField.svelte";
import TableSortButton from "./Table/TableSortButton.svelte";
import PrettyData, { getPrettifyOptions } from "./Table/PrettyData.svelte";
import tableActions from "./Table/tableActions";
import Loader from "../General/Loader.svelte";
export let options, functions;
const tableName = getQueryFirstSelectionName(options === null || options === void 0 ? void 0 : options.query);
let sortStates = (_a = options.defaultOrderBy) !== null && _a !== void 0 ? _a : {};
let notActuallyUsedWhereValues = {};
let whereValues = {};
let tableKeyParentsByKey;
let tableData = [];
let tableKeys = [];
let selectedRow;
const queryStore = new QueryStore({
    query: options === null || options === void 0 ? void 0 : options.query,
    variables: { order_by: {} },
    paginate: true,
    insertQueryVariables: { order_by: `[${tableName}_order_by!]` },
});
const queryVariablesStore = queryStore.variablesStore;
const queryDataStore = queryStore.getDataStore();
const getOrderBy = () => !tableKeyParentsByKey
    ? {}
    : Object.fromEntries(Object.entries(sortStates)
        .filter(([k, v]) => v != null)
        .map(([k, v]) => {
        var _a, _b;
        return tableKeyParentsByKey[k].length == 0
            ? [(_a = findAliasName(k)) !== null && _a !== void 0 ? _a : k, v]
            : [
                //Sorry to anyone who has to debug this function
                tableKeyParentsByKey[k][0],
                [...tableKeyParentsByKey[k].slice(1), (_b = findAliasName(k)) !== null && _b !== void 0 ? _b : k, v].reduceRight((a, cv) => {
                    let n = {};
                    n[cv] = a;
                    return n;
                }),
            ];
    }));
const getWheres = () => Object.fromEntries(Object.entries(whereValues)
    .filter(([k, v]) => v != undefined)
    .map(([k, v]) => {
    var _a, _b;
    return tableKeyParentsByKey[k].length == 0
        ? [(_a = findAliasName(k)) !== null && _a !== void 0 ? _a : k, findWhereValue(k, v)]
        : [
            //Sorry to anyone who has to debug this function
            tableKeyParentsByKey[k][0],
            [...tableKeyParentsByKey[k].slice(1), (_b = findAliasName(k)) !== null && _b !== void 0 ? _b : k, findWhereValue(k, v)].reduceRight((a, cv) => {
                let n = {};
                n[cv] = a;
                return n;
            }),
        ];
})
    .map(([k, v]) => [`where_${k}`, v]));
const getAvailableActions = () => { var _a, _b; return (_b = (_a = options === null || options === void 0 ? void 0 : options.actions) === null || _a === void 0 ? void 0 : _a.map(getTableAction)) !== null && _b !== void 0 ? _b : []; };
const getTableAction = (action) => {
    const key = typeof action == "string" ? action : action["key"];
    return { ...tableActions[key](action), key: key };
};
$: console.log(selectedRow);
$: if ((_b = $queryDataStore === null || $queryDataStore === void 0 ? void 0 : $queryDataStore.data) === null || _b === void 0 ? void 0 : _b.data) {
    tableData = $queryDataStore["data"]["data"].map((en) => {
        const { data, keyParentsByKey } = flattenData(en);
        tableKeyParentsByKey = keyParentsByKey;
        return data;
    });
    selectedRow = null;
}
var delayTimer;
const timeStuffsIdk = () => {
    clearTimeout(delayTimer);
    delayTimer = setTimeout(() => {
        whereValues = { ...notActuallyUsedWhereValues };
    }, 500);
};
$: timeStuffsIdk(), notActuallyUsedWhereValues;
const removeKeys = (obj, pattern) => Object.fromEntries(Object.entries(obj).filter(([k, v]) => !pattern.test(k)));
$: if (tableData[0])
    tableKeys = Object.keys(tableData[0]).filter((key) => { var _a; return !((_a = options.queryHidden) !== null && _a !== void 0 ? _a : []).includes(key); });
$: if (Object.keys(sortStates).length > 0) {
    ($queryVariablesStore = { ...$queryVariablesStore, order_by: getOrderBy() }), sortStates;
}
$: if (Object.keys(whereValues).length > 0) {
    ($queryVariablesStore = { ...removeKeys($queryVariablesStore, /where_(.*?)/), ...getWheres() }), whereValues;
}
const findAliasName = (alias, source = getQueryFirstSelection(options.query)) => {
    var _a;
    if ((options === null || options === void 0 ? void 0 : options.customAliasNames) && alias && options.customAliasNames[alias])
        return options.customAliasNames[alias].name;
    const selections = getSourceSelections(source);
    let found;
    for (const sel of selections) {
        if (((_a = sel.alias) === null || _a === void 0 ? void 0 : _a.value) == alias)
            found = sel["name"]["value"];
        if (sel.selectionSet) {
            const subSearch = findAliasName(alias, sel);
            if (subSearch)
                found = subSearch;
        }
        if (found)
            break;
    }
    return found;
};
const findWhereValue = (k, v) => {
    var _a;
    const queryVarType = getQueryVariableType(options.query, `where_${(_a = findAliasName(k)) !== null && _a !== void 0 ? _a : k}`);
    if (queryVarType === null || queryVarType === void 0 ? void 0 : queryVarType.includes("_enum_comparison_exp")) {
        return { _eq: v };
    }
    switch (queryVarType) {
        case "String_comparison_exp":
            if (v === "")
                return {};
            return { _ilike: `%${v}%` };
        case "uuid_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "Int_comparison_exp":
            return {
                _gte: v[0],
                _lte: v[1],
            };
        case "timestamptz_comparison_exp":
            return {
                _gte: v[0],
                _lte: v[1],
            };
        case "numeric_comparison_exp":
            return {
                _gte: v[0],
                _lte: v[1],
            };
        case "bigint_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "date_comparison_exp":
            return {
                _gte: v[0],
                _lte: v[1],
            };
        case "Boolean_comparison_exp":
            return { _eq: v };
        case "timestamp_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "jsonb_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
    }
};
const getPaginationIndexes = (len, currentPage) => {
    const array = [...Array(len + 1)].map((_, i) => i.toString()).slice(1);
    if (array.length <= 5)
        return array;
    const firstPage = currentPage === 0 ? [] : ["1", ...(currentPage !== 1 ? ["..."] : [])];
    const lastPage = array.length - currentPage <= 2
        ? []
        : [...(currentPage !== array.length - 3 ? ["..."] : []), ...(currentPage === 0 ? [(array.length - 1).toString()] : []), array.length.toString()];
    return [...firstPage, ...array.slice(currentPage, currentPage + 2), ...lastPage];
};
const findFilterOptions = (k) => {
    var _a, _b;
    const queryVarType = getQueryVariableType(options.query, `where_${(_a = findAliasName(k)) !== null && _a !== void 0 ? _a : k}`);
    if (queryVarType === null || queryVarType === void 0 ? void 0 : queryVarType.includes("_enum_comparison_exp")) {
        return { type: "select", optionsQuery: options["varOptions"][`where_${(_b = findAliasName(k)) !== null && _b !== void 0 ? _b : k}`]["optionsQuery"] };
    }
    switch (queryVarType) {
        case "String_comparison_exp":
            return { type: "text" };
        case "uuid_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "Int_comparison_exp":
            return { type: "numberrange" };
        case "timestamptz_comparison_exp":
            return { type: "datetimerange" };
        case "numeric_comparison_exp":
            return { type: "decimalrange" };
        case "bigint_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "date_comparison_exp":
            return { type: "daterange" };
        case "Boolean_comparison_exp":
            return { type: "select", options: [false, true] };
        case "timestamp_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "jsonb_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        default:
            return { type: "text", placeholder: "Missing Filter Type" };
    }
};
</script>

<div class="overflow-x-auto">
  <div class="bg-white dark:bg-gray-800  mb-5 align-middle inline-block min-w-full overflow-hidden sm:rounded-lg border border-gray-400 dark:border-gray-900">
    {#key options?.actions}
      <GeneralHeading title={stringSepToNorm(tableName, '_')}>
        {#each getAvailableActions() as action}
          {#if action['roles'] ? auth.helper.checkRoles($userRoles, action['roles']) : true}
            <button
              type="button"
              id={tableName + '-button' + action['key']}
              class="actionButton"
              class:clickableAction={action.needSelectedRow ? !selectedRow : false}
              on:click={() => action.action(tableName, selectedRow, () => {
                  selectedRow = null;
                  queryStore.doQuery();
                })}
              disabled={action.needSelectedRow ? !selectedRow : false}
            >
              {action?.label}
            </button>
          {/if}
        {/each}
      </GeneralHeading>
    {/key}

    {#if $queryDataStore?.loading}
      <Loader />
    {:else if !tableKeys}
      <GeneralHeading title="No data found" />
    {:else}
      <table class="min-w-full m-0">
        <thead class="m-0">
          <tr>
            {#each tableKeys as key}
              <th class="py-2 text-xs uppercase">
                <div class="flex flex-row">
                  <label class="flex" for={tableName + '-' + key}> <span class="my-auto">{stringSepToNorm(key, '_')}</span> </label>
                  <TableSortButton bind:state={sortStates[key]} />
                </div>
              </th>
            {/each}
          </tr>
          <tr>
            {#each tableKeys as key}
              <th class="py-2">
                <form on:submit|preventDefault autocomplete="off"><FormField
                  bind:value={notActuallyUsedWhereValues[key]}
                  fieldData={{ field: key, label: key, placeholder: 'Search', required: false, width: 'full', ...findFilterOptions(key) }}
                  hideLabel
                /></form>
              </th>
            {/each}
          </tr>
        </thead>
        <tbody>
          {#if tableData}
            {#each tableData as dataEntry}
              <tr on:click={() => (selectedRow == dataEntry ? (selectedRow = null) : (selectedRow = dataEntry))} class:selectedRow={selectedRow == dataEntry}>
                {#each tableKeys as key, keyIndex}
                  {#if Array.isArray(dataEntry[key])}
                    <td class="py-2 text-xs">{dataEntry[key].map((en) => Object.values(en).join('-')).join(', ')}</td>
                  {:else}
                    <td class="py-2 text-xs">
                      <PrettyData data={dataEntry[key]} prettifyOptionsForData={getPrettifyOptions((options?.prettifyOptions ?? {})[key], functions)} />
                    </td>
                  {/if}
                {/each}
              </tr>
            {/each}
            {#each Array(12 - tableData.length) as _}
              <tr>
                {#each tableKeys as key, keyIndex}
                  <td class="py-2 text-xs text-white"><span class="dark:text-gray-800">.</span></td>
                {/each}
              </tr>
            {/each}
          {:else}
            <tr>
              {#each tableKeys as key, keyIndex}
                <td class="py-2 text-xs">
                  {#if keyIndex == 0}No data found{/if}
                </td>
              {/each}
            </tr>
          {/if}
        </tbody>
        <tfoot>
          {#key tableData}
            <tr class="bg-white dark:bg-gray-800 ">
              <td class="bottom font-semibold">
                Showing
                {queryStore.currentOffset}
                to
                {queryStore.currentOffset + queryStore.offsetPageLength > queryStore.totalCount ? queryStore.totalCount : queryStore.currentOffset + queryStore.offsetPageLength}
                of
                {queryStore.totalCount}.
              </td>
              {#each tableKeys as key, keyIndex}
                {#if keyIndex == tableKeys.length - 1}
                  <td class="flex bottom">
                    <nav class="ml-auto self-end z-0 inline-flex shadow-sm rounded-md">
                      <button on:click={() => queryStore.lastPage()} class="paginationButton" aria-label="Previous">
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            fill-rule="evenodd"
                            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0
                      010-1.414l4-4a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </button>

                      {#each getPaginationIndexes(queryStore.pageCount, queryStore.currentPage) as index}
                        <button
                          disabled={isNaN(parseInt(index))}
                          on:click={() => queryStore.changePage(parseInt(index) - 1)}
                          class="paginationButton {parseInt(index) == queryStore.currentPage + 1 ? 'active' : ''}"
                        >
                          {index}
                        </button>
                      {/each}

                      <button on:click={() => queryStore.nextPage()} class="paginationButton" aria-label="Next">
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path
                            fill-rule="evenodd"
                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010
                      1.414l-4 4a1 1 0 01-1.414 0z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </button>
                    </nav>
                  </td>
                {:else if keyIndex != 0}
                  <td class="bottom" />
                {/if}
              {/each}
            </tr>
          {/key}
        </tfoot>
      </table>
    {/if}
  </div>
</div>

<style>
  :global(.paginationButton) {

    position: relative;

    display: inline-flex;

    align-items: center;

    border-top-width: 1px;

    border-bottom-width: 1px;

    --tw-border-opacity: 1;

    border-color: rgba(172, 172, 175, var(--tw-border-opacity));

    --tw-bg-opacity: 1;

    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));

    padding-top: 0.25rem;

    padding-bottom: 0.25rem;

    padding-left: 0.25rem;

    padding-right: 0.25rem;

    font-size: 0.75rem;

    line-height: 1rem;

    font-weight: 500;

    line-height: 1rem;

    --tw-text-opacity: 1;

    color: rgba(47, 47, 54, var(--tw-text-opacity));

    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 150ms;

    transition-duration: 150ms;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

  :global(.dark .paginationButton) {

    --tw-bg-opacity: 1;

    background-color: rgba(28, 28, 32, var(--tw-bg-opacity));

    --tw-text-opacity: 1;

    color: rgba(203, 203, 205, var(--tw-text-opacity))
}

  :global(.paginationButton.active) {

    background-color: var(--secondary-200)
}

  :global(.dark .paginationButton.active) {

    background-color: var(--secondary-700)
}

  .paginationButton + .paginationButton {

    border-left-width: 1px
}

  .paginationButton:first-child {

    border-top-left-radius: 0.18rem;

    border-bottom-left-radius: 0.18rem;

    border-left-width: 1px
}

  .paginationButton:last-child {

    border-top-right-radius: 0.18rem;

    border-bottom-right-radius: 0.18rem;

    border-right-width: 1px
}

  .paginationButton:hover {

    --tw-text-opacity: 1;

    color: rgba(109, 109, 114, var(--tw-text-opacity))
}

  .paginationButton:focus {

    z-index: 10;

    --tw-border-opacity: 1;

    border-color: rgba(147, 197, 253, var(--tw-border-opacity));

    --tw-shadow: 0 0 0 3px rgba(191, 219, 254, .5);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

    outline: 2px solid transparent;

    outline-offset: 0
}

  :global(.paginationButton:active) {

    --tw-bg-opacity: 1;

    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));

    --tw-text-opacity: 1;

    color: rgba(47, 47, 54, var(--tw-text-opacity))
}

  :global(.dark .paginationButton:active) {

    --tw-bg-opacity: 1;

    background-color: rgba(28, 28, 32, var(--tw-bg-opacity));

    --tw-text-opacity: 1;

    color: rgba(203, 203, 205, var(--tw-text-opacity))
}

  .clickableAction {

    cursor: not-allowed;

    opacity: 0.5
}

  :global(th) {

    border-bottom-width: 1px;

    --tw-border-opacity: 1;

    border-color: rgba(203, 203, 205, var(--tw-border-opacity));

    --tw-bg-opacity: 1;

    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));

    padding-left: 0.75rem;

    padding-right: 0.75rem;

    text-align: left;

    font-weight: 700;

    line-height: .75rem;

    letter-spacing: 0.05em;

    --tw-text-opacity: 1;

    color: rgba(47, 47, 54, var(--tw-text-opacity))
}

  :global(.dark th) {

    --tw-border-opacity: 1;

    border-color: rgba(35, 35, 41, var(--tw-border-opacity));

    --tw-bg-opacity: 1;

    background-color: rgba(28, 28, 32, var(--tw-bg-opacity));

    --tw-text-opacity: 1;

    color: rgba(203, 203, 205, var(--tw-text-opacity))
}

  td {

    overflow-x: hidden;

    overflow: hidden;

    text-overflow: ellipsis;

    white-space: nowrap;

    padding-left: 0.75rem;

    padding-right: 0.75rem;

    line-height: 1rem
}

  :global(tr) {

    border-bottom-width: 1px;

    --tw-border-opacity: 1;

    border-color: rgba(203, 203, 205, var(--tw-border-opacity));

    --tw-bg-opacity: 1;

    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));

    --tw-text-opacity: 1;

    color: rgba(23, 23, 26, var(--tw-text-opacity))
}

  :global(.dark tr) {

    --tw-border-opacity: 1;

    border-color: rgba(35, 35, 41, var(--tw-border-opacity));

    --tw-bg-opacity: 1;

    background-color: rgba(28, 28, 32, var(--tw-bg-opacity));

    --tw-text-opacity: 1;

    color: rgba(203, 203, 205, var(--tw-text-opacity))
}

  :global(tfoot tr td) {

    align-content: flex-end;

    align-items: flex-end;

    padding-top: 0.5rem;

    padding-bottom: 0.5rem;

    padding-left: 0.75rem;

    padding-right: 0.75rem;

    text-align: left;

    font-size: 0.75rem;

    line-height: 1rem;

    font-weight: 700;

    line-height: .75rem;

    letter-spacing: 0.05em;

    --tw-text-opacity: 1;

    color: rgba(47, 47, 54, var(--tw-text-opacity))
}

  :global(.dark tfoot tr td) {

    --tw-text-opacity: 1;

    color: rgba(203, 203, 205, var(--tw-text-opacity))
}

  :global(.selectedRow) {

    background-color: var(--primary-200)
}

  :global(.dark .selectedRow) {

    background-color: var(--primary-800)
}</style>
