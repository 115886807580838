<script lang="ts">var _a;
import { onMount } from "svelte";
import { queryClient } from "@src/GraphQL/query";
import Loader from "../General/Loader.svelte";
import PrettyData, { getPrettifyOptions } from "./Table/PrettyData.svelte";
import { flattenData, stringSepToNorm } from "../random";
export let options, functions;
const gridSize = (_a = options.gridSize) !== null && _a !== void 0 ? _a : 3;
let loading = true;
let infoViewersUI = [];
onMount(async () => {
    var _a, _b;
    for (const infoViewer of options.infoViewers) {
        const data = (await queryClient({ query: `${infoViewer.query} ` })).data;
        const dataFirstArrVal = Object.values(data !== null && data !== void 0 ? data : {})[0];
        const flatData = flattenData(Array.isArray(dataFirstArrVal) ? dataFirstArrVal : data).data;
        infoViewersUI.push({
            data: Object.entries(flatData),
            prettifyOptionsForDataValues: (_a = infoViewer.prettifyOptionsForDataValues) !== null && _a !== void 0 ? _a : {},
            prettifyOptionsForLabel: (_b = infoViewer.prettifyOptionsForLabel) !== null && _b !== void 0 ? _b : {},
        });
    }
    loading = false;
});
</script>

{#if !loading}
  <div>
    <div class="grid grid-cols-1 gap-5 sm:grid-cols-1 lg:grid-cols-{gridSize} mb-5">
      {#each infoViewersUI as infoViewer}
        <div class="bg-white dark:bg-gray-800  overflow-hidden rounded-lg border border-gray-400 dark:border-gray-900">
          <div class="px-3 py-3">
            <table class="w-full">
              {#each infoViewer.data as [key, data], i}
                <tr>
                  <td class={infoViewer.data.length - 1 !== i ? 'pb-1' : ''}>
                    <PrettyData
                      data="{stringSepToNorm(key, '_')}:"
                      prettifyOptionsForData={getPrettifyOptions(infoViewer.prettifyOptionsForLabel, functions)}
                    />
                  </td>
                  <td class={infoViewer.data.length - 1 !== i ? 'pb-1' : ''}>
                    <PrettyData {data} prettifyOptionsForData={getPrettifyOptions(infoViewer.prettifyOptionsForDataValues[key], functions)} />
                  </td>
                </tr>
              {/each}
            </table>
          </div>
        </div>
      {:else}
        <h3 class="py-4 pl-4">No InfoViewers Found</h3>
      {/each}
    </div>
  </div>
{:else}
  <div class="grid grid-cols-1 md:grid-cols-{gridSize} gap-5 mb-5">
    {#each options.infoViewers as infoViewer}
      <div class="bg-white dark:bg-gray-800  overflow-hidden rounded-lg border border-gray-400 dark:border-gray-900">
        <div class="px-3 py-3">
          <div class="flex items-center">
            <Loader />
          </div>
        </div>
      </div>
    {:else}
      <h3 class="py-4 pl-4">No infoViewers Found</h3>
    {/each}
  </div>
{/if}

<style>
  tr {
    border-bottom-width: 0px;
}
  
  .icon-white {
    filter: brightness(0) invert(1);
  }</style>
