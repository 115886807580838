<script lang="ts">var _a;
import FullCalendar, { Draggable } from "svelte-fullcalendar";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import { modalOpts } from "@src/stores";
import { mutateClient } from "../GraphQL/mutate";
import { rx } from "../rxfsm";
import auth from "../auth";
const userRoles = auth.claims.pipe(rx.filter((val) => val != null), rx.pluck("https://hasura.io/jwt/claims"), rx.pluck("x-hasura-allowed-roles"));
import GeneralHeading from "../General/GeneralHeading.svelte";
import interactionPlugin from "@fullcalendar/interaction";
import { stringSepToNorm, flattenData } from "../random";
import { QueryStore } from "../GraphQL/query";
import { getQueryFirstSelectionName, getQueryFirstSelection, getSourceSelections, getQueryVariableType } from "../GraphQL/helpers";
import FormField from "./Form/FormField.svelte";
import TableSortButton from "./Table/TableSortButton.svelte";
import tableActions from "./Table/tableActions";
import Loader from "../General/Loader.svelte";
export let options;
const tableName = getQueryFirstSelectionName(options === null || options === void 0 ? void 0 : options.query);
let sortStates = {};
let whereValues = {};
let tableKeyParentsByKey;
let tableData = [];
let tableKeys = [];
let selectedRow;
let queryStore;
let queryVariablesStore;
let queryDataStore;
const getOrderBy = () => Object.fromEntries(Object.entries(sortStates)
    .filter(([k, v]) => v != null)
    .map(([k, v]) => {
    var _a, _b;
    return tableKeyParentsByKey[k].length == 0
        ? [(_a = findAliasName(k)) !== null && _a !== void 0 ? _a : k, v]
        : [
            //Sorry to anyone who has to debug this function
            tableKeyParentsByKey[k][0],
            [...tableKeyParentsByKey[k].slice(1), (_b = findAliasName(k)) !== null && _b !== void 0 ? _b : k, v].reduceRight((a, cv) => {
                let n = {};
                n[cv] = a;
                return n;
            }),
        ];
}));
const getWheres = () => Object.fromEntries(Object.entries(whereValues)
    .filter(([k, v]) => v != undefined)
    .map(([k, v]) => {
    var _a, _b;
    return tableKeyParentsByKey[k].length == 0
        ? [(_a = findAliasName(k)) !== null && _a !== void 0 ? _a : k, findWhereValue(k, v)]
        : [
            //Sorry to anyone who has to debug this function
            tableKeyParentsByKey[k][0],
            [...tableKeyParentsByKey[k].slice(1), (_b = findAliasName(k)) !== null && _b !== void 0 ? _b : k, findWhereValue(k, v)].reduceRight((a, cv) => {
                let n = {};
                n[cv] = a;
                return n;
            }),
        ];
})
    .map(([k, v]) => [`where_${k}`, v]));
const getAvailableActions = () => { var _a, _b; return (_b = (_a = options === null || options === void 0 ? void 0 : options.actions) === null || _a === void 0 ? void 0 : _a.map(getTableAction)) !== null && _b !== void 0 ? _b : []; };
const getTableAction = (action) => {
    const key = typeof action == "string" ? action : action["key"];
    return { ...tableActions[key](action), key: key };
};
$: if ((_a = $queryDataStore === null || $queryDataStore === void 0 ? void 0 : $queryDataStore.data) === null || _a === void 0 ? void 0 : _a.data) {
    tableData = $queryDataStore["data"]["data"].map((en) => {
        const { data, keyParentsByKey } = flattenData(en);
        tableKeyParentsByKey = keyParentsByKey;
        return { end: new Date(new Date(data === null || data === void 0 ? void 0 : data.start).getTime() + 30 * 60000).toISOString(), ...data };
        return data;
    });
    selectedRow = null;
}
const removeKeys = (obj, pattern) => Object.fromEntries(Object.entries(obj).filter(([k, v]) => !pattern.test(k)));
$: if (tableData[0])
    tableKeys = Object.keys(tableData[0]).filter((key) => { var _a; return !((_a = options.queryHidden) !== null && _a !== void 0 ? _a : []).includes(key); });
$: if (Object.keys(sortStates).length > 0)
    ($queryVariablesStore = { ...$queryVariablesStore, order_by: getOrderBy() }), sortStates;
$: if (Object.keys(whereValues).length > 0)
    ($queryVariablesStore = { ...removeKeys($queryVariablesStore, /where_(.*?)/), ...getWheres() }), whereValues;
const findAliasName = (alias, source = getQueryFirstSelection(options.query)) => {
    var _a;
    const selections = getSourceSelections(source);
    let found;
    for (const sel of selections) {
        if (((_a = sel.alias) === null || _a === void 0 ? void 0 : _a.value) == alias)
            found = sel["name"]["value"];
        if (sel.selectionSet) {
            const subSearch = findAliasName(alias, sel);
            if (subSearch)
                found = subSearch;
        }
        if (found)
            break;
    }
    return found;
};
const findWhereValue = (k, v) => {
    var _a;
    const queryVarType = getQueryVariableType(options.query, `where_${(_a = findAliasName(k)) !== null && _a !== void 0 ? _a : k}`);
    if (queryVarType === null || queryVarType === void 0 ? void 0 : queryVarType.includes("_enum_comparison_exp")) {
        return { _eq: v };
    }
    switch (queryVarType) {
        case "String_comparison_exp":
            return { _ilike: `%${v}%` };
        case "uuid_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "Int_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "timestamptz_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "numeric_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "bigint_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "date_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "Boolean_comparison_exp":
            return { _eq: v };
        case "timestamp_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "jsonb_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
    }
};
const findFilterOptions = (k) => {
    var _a, _b;
    const queryVarType = getQueryVariableType(options.query, `where_${(_a = findAliasName(k)) !== null && _a !== void 0 ? _a : k}`);
    if (queryVarType === null || queryVarType === void 0 ? void 0 : queryVarType.includes("_enum_comparison_exp")) {
        return { type: "select", optionsQuery: options["varOptions"][`where_${(_b = findAliasName(k)) !== null && _b !== void 0 ? _b : k}`]["optionsQuery"] };
    }
    switch (queryVarType) {
        case "String_comparison_exp":
            return { type: "text" };
        case "uuid_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "Int_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "timestamptz_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "numeric_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "bigint_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "date_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "Boolean_comparison_exp":
            return { type: "select", options: [false, true] };
        case "timestamp_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        case "jsonb_comparison_exp":
            throw "Implement comparison type of " + queryVarType;
        default:
            return { type: "text", placeholder: "Missing Filter Type" };
    }
};
let calendarRef;
let calendarOptions = {
    dateClick: handleDateClick,
    eventClick: handleEventClick,
    eventMouseEnter: handleEventMouseover,
    eventMouseLeave: handleEventMouseleave,
    eventDrop: handleEventMove,
    select: handleDateSelect,
    datesSet: handleNewDates,
    droppable: true,
    editable: true,
    navLinks: true,
    selectable: true,
    events: [
        // initial event data
        { title: "New Event", start: new Date() },
    ],
    dayMaxEvents: 4,
    eventLimitText: "More..",
    views: {
        dayGrid: {
            dayMaxEvents: 4,
        },
    },
    initialView: "dayGridMonth",
    plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
    headerToolbar: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
    },
    height: "auto",
    weekends: true,
};
let dateChangeLoad = false;
$: if (!($queryDataStore === null || $queryDataStore === void 0 ? void 0 : $queryDataStore.loading) && tableData) {
    const { events } = calendarOptions;
    const calendarEvents = [...tableData];
    calendarOptions = {
        ...calendarOptions,
        events: calendarEvents,
    };
    dateChangeLoad = false;
}
function handleNewDates(dateInfo) {
    dateChangeLoad = true;
    if (!queryDataStore) {
        queryStore = new QueryStore({
            query: options === null || options === void 0 ? void 0 : options.query,
            variables: { start: dateInfo.start, end: dateInfo.end },
            paginate: false,
        });
        queryVariablesStore = queryStore.variablesStore;
        queryDataStore = queryStore.getDataStore();
    }
    else {
        if ($queryVariablesStore) {
            $queryVariablesStore = { ...$queryVariablesStore, start: dateInfo.start, end: dateInfo.end };
        }
    }
}
function handleEventMouseover(event, jsEvent, view) {
    event.event.setProp("backgroundColor", "green");
    event.event.setProp("borderColor", "green");
    event.el.style = "cursor:pointer;";
}
function handleEventMouseleave(event, jsEvent, view) {
    if (selectedRow) {
        if (selectedRow.id != event.event.id) {
            event.event.setProp("backgroundColor", "#3788d8");
            event.event.setProp("borderColor", "#3788d8");
        }
    }
    else {
        event.event.setProp("backgroundColor", "#3788d8");
        event.event.setProp("borderColor", "#3788d8");
    }
}
function handleEventMove(eventDropInfo) {
    if (options.moveMutation) {
        let title = eventDropInfo.oldEvent.title;
        let oldTime = eventDropInfo.oldEvent.start;
        let newTime = eventDropInfo.event.start;
        modalOpts.set({
            title: `Move ${title}?`,
            description: `Are you sure you want to move ${title} from ${oldTime} to ${newTime}`,
            color: "blue",
            icon: "warn",
            exclusions: [tableName + "-buttondelete"],
            closeCallback: async (calledAction) => {
                if (!calledAction) {
                    eventDropInfo.revert();
                }
            },
            action: {
                name: "Move",
                callback: async () => {
                    let variables;
                    if (eventDropInfo.event.end) {
                        variables = {
                            id: eventDropInfo.oldEvent.id,
                            start: eventDropInfo.event.start,
                            end: eventDropInfo.event.end,
                        };
                    }
                    else {
                        variables = {
                            id: eventDropInfo.oldEvent.id,
                            start: eventDropInfo.event.start,
                        };
                    }
                    await mutateClient({
                        mutation: options.moveMutation,
                        variables: variables,
                    });
                },
            },
        });
    }
    else {
        eventDropInfo.revert();
    }
}
function handleDateClick(select) { }
function handleDateSelect(select) {
    let newAction = getAvailableActions().find((element) => element.key == "create");
    if (newAction) {
        console.log(newAction, tableName, selectedRow);
        newAction.action(tableName, selectedRow, () => {
            selectedRow = null;
            queryStore.doQuery();
        });
    }
}
function handleEventClick(event) {
    let id = event.event.id;
    let calendarApi = calendarRef.getAPI();
    //get current selected one and if exits or different unselect
    if (selectedRow) {
        let otherSelected = calendarApi.getEventById(selectedRow.id);
        otherSelected.setProp("backgroundColor", "#3788d8");
        otherSelected.setProp("borderColor", "#3788d8");
    }
    event.event.setProp("backgroundColor", "green");
    event.event.setProp("borderColor", "green");
    selectedRow = {
        id: id,
    };
}
</script>

<div class="bg-white dark:bg-gray-800  mb-5 align-middle inline-block min-w-full overflow-hidden sm:rounded-lg shadow pt-2">
  {#key options?.actions}
    <GeneralHeading title={stringSepToNorm(tableName, '_')}>
      {#each getAvailableActions() as action}
        {#if action['roles'] ? auth.helper.checkRoles($userRoles, action['roles']) : true}
          <button
            type="button"
            id={tableName + '-button' + action['key']}
            class="actionButton"
            class:clickableAction={action.needSelectedRow ? !selectedRow : false}
            on:click={() => action.action(tableName, selectedRow, () => {
                selectedRow = null;
                queryStore.doQuery();
              })}
            disabled={action.needSelectedRow ? !selectedRow : false}
          >
            {action?.label}
          </button>
        {/if}
      {/each}
    </GeneralHeading>
    <div class="flex flex-row px-4 py-2">
      {#each tableKeys as key}
        <div class="py-2 flex flex-col mr-3">
          <span class="text-md">{stringSepToNorm(key, '_')}</span>
          <FormField
            bind:value={whereValues[key]}
            fieldData={{ field: key, label: key, placeholder: 'Search', width: 'full', ...findFilterOptions(key) }}
            hideLabel
          />
        </div>
      {/each}
    </div>
  {/key}
  <div class="px-6 py-4 calenderWrapper grid" on:click={(ev) => ev.stopPropagation()}>
    {#if dateChangeLoad}
      <div style="grid-area: 1 / 1 / 1 / 1" class="bg-gray-50 dark:bg-gray-600 z-50 opacity-75">
        <div class="my-auto h-full">
          <Loader svgClasses="my-auto w-10 h-10" classes="my-auto mx-auto w-12 h-full flex align-middle" />
        </div>
      </div>
    {/if}
    <div style="grid-area: 1 / 1 / 1 / 1" class="overflow-scroll customscrollbar">
      <FullCalendar bind:this={calendarRef} options={calendarOptions} />
    </div>
  </div>
</div>

<style>
  .clickableAction {
    cursor: not-allowed;
    opacity: 0.5
}</style>
