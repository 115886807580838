<script lang="ts">export let id, value = false, required, disabled;
export let isUnsavedUpdates = false;
export let roundRightonFocus = true;
export let isFocused = false;
$: if (value == null || value == undefined)
    value = false;
</script>

<div
  class="toggleBtnWrapper form-input border border-gray-500 focus:border-primary-600 dark:border-gray-200 ring-primary-600 dark:ring-gray-200 focus-within:ring-1 {!isUnsavedUpdates ? 'bg-transparent px-0' : ''} {!!disabled ? 'bg-gray-200' : ''} {isUnsavedUpdates && !roundRightonFocus ? 'rounded-r-none' : ''}"
>
  <button
    type="button"
    on:click={() => (value = !value)}
    aria-pressed={!!value}
    class="{value ? 'bg-secondary-600' : 'bg-gray-200 dark:bg-gray-400'} toggleBtn focus:outline-none"
  >
    <span class="sr-only">Use setting</span>
    <span
      class="{value ? 'translate-x-5' : 'translate-x-0'} relative inline-block h-full w-4  bg-white dark:bg-gray-200  shadow transform ring-0 transition ease-in-out duration-200"
    >
      <span class:show={!value} class:hide={!!value} class="toggle" aria-hidden="true">
        <svg class="h-2.5 w-2.5 text-gray-400" fill="none" viewBox="0 0 12 12">
          <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </span>
      <span class:show={!!value} class:hide={!value} class="toggle" aria-hidden="true">
        <svg class="h-2.5 w-2.5 text-secondary-600" fill="currentColor" viewBox="0 0 12 12">
          <path
            d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
          />
        </svg>
      </span>
    </span>
  </button>
</div>

<style>
  .toggleBtnWrapper {
    height: calc(2rem - 0.5px);
    padding-top: 0.285rem;
    padding-bottom: 0.285rem;
    display: block;
    width: 100%;
  }
  .toggleBtn {
    position: relative;
    margin-right: auto;
    display: flex;
    height: 100%;
    width: 2.5rem;
    flex-shrink: 0;
    cursor: pointer;
    border-width: 2px;
    border-color: transparent;
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
  .toggle {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
  .show {
    opacity: 1;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

  .hide {
    opacity: 0;
    transition-duration: 100ms;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}</style>
