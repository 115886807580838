<script type="ts">var _a;
import { notificationOpts } from "../stores";
import { onMount } from "svelte";
import Yace from "./JsonEditor/Yace.js";
import history from "yace/dist/plugins/history.js";
import tab from "yace/dist/plugins/tab.js";
import { QueryStore } from "../GraphQL/query";
import { mutateClient } from "../GraphQL/mutate";
import { Provider } from "../provider";
export let options;
let editor, editorDiv;
const plugins = [
    history(),
    tab(),
];
const format = (useValue = editor === null || editor === void 0 ? void 0 : editor.value) => {
    let hadError = false;
    try {
        const parsedJson = JSON.parse(useValue);
        editor.update({ value: JSON.stringify(parsedJson, undefined, 2) });
    }
    catch (e) {
        hadError = true;
        if (e.name == "SyntaxError") {
            const errorSpot = parseInt(e.message.replace(/Unexpected (.*) in JSON at position /g, ""));
            editor.textarea.focus();
            editor.update({ selectionStart: errorSpot, selectionEnd: errorSpot + 1 });
            notificationOpts.set({
                title: "JSON Error Highlighted",
                color: "red",
                icon: "info",
                timeout: 5000,
            });
        }
        else {
            throw e;
        }
    }
    return { hadError };
};
const save = async () => {
    const { hadError } = format();
    if (!hadError) {
        await mutateClient({
            mutation: options.mutation,
            variables: {
                editor_value: JSON.parse(editor.value),
            },
        });
        if (options.changecontexts) {
            //@ts-ignore
            const [, , authSender] = Provider.of("authState", editorDiv);
            authSender.child["changecontexts"]({ changingContext: options.changecontexts, stopRedirectAfterChangeContext: true });
        }
        else {
            notificationOpts.set({
                title: "Saved Value",
                color: "green",
                icon: "info",
                timeout: 5000,
            });
        }
    }
};
const queryStore = new QueryStore({ query: options === null || options === void 0 ? void 0 : options.query });
const queryDataStore = queryStore.getDataStore();
$: if (editor && ((_a = $queryDataStore === null || $queryDataStore === void 0 ? void 0 : $queryDataStore.data) === null || _a === void 0 ? void 0 : _a.data))
    editor.update({ value: JSON.stringify($queryDataStore["data"]["data"]["data"], undefined, 2) });
function handleKeydown(event) {
    if (event.key === "s" && event.ctrlKey === true) {
        event.preventDefault();
        save();
    }
}
onMount(() => {
    editor = new Yace(editorDiv, {
        value: JSON.stringify($queryDataStore, undefined, 2),
        plugins,
    });
    editor.textarea.spellcheck = false;
    editor.root.className = "customscrollbar";
});
</script>

<svelte:window on:keydown={handleKeydown} />

<div class="jsoneditor bg-white dark:bg-gray-800 rounded-lg shadow px-5 py-6 sm:px-6 mb-5">
  <div class="rounded-lg flex flex-row" style="min-height: 55vh">
    <span class="relative z-0 inline-flex shadow-sm rounded-md mb-auto">
      <button type="button" on:click={() => format()} class="rounded-l-md"> Format </button>

      <button type="button" on:click={save} class="-ml-px rounded-r-md"> Save </button>
    </span>
    <div class="editor-container">
      <div bind:this={editorDiv} />
    </div>
  </div>
</div>

<style global>
  :global(.jsoneditor) :global(button) {
    position: relative;
    display: inline-flex;
    align-items: center;
    border-width: 1px;
    border-color: var(--secondary-300);
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    line-height: 1.25rem;
    --tw-text-opacity: 1;
    color: rgba(35, 35, 41, var(--tw-text-opacity));
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    transition-duration: 150ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

  :global(.dark) :global(.jsoneditor) :global(button) {
    --tw-bg-opacity: 1;
    background-color: rgba(28, 28, 32, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(109, 109, 114, var(--tw-text-opacity));
}

  :global(.jsoneditor) :global(button:hover) {
    --tw-text-opacity: 1;
    color: rgba(47, 47, 54, var(--tw-text-opacity));
}

  :global(.dark) :global(.jsoneditor) :global(button:hover) {
    --tw-text-opacity: 1;
    color: rgba(203, 203, 205, var(--tw-text-opacity));
}

  :global(.jsoneditor) :global(button:focus) {
    z-index: 10;
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
    --tw-shadow: 0 0 0 3px rgba(191, 219, 254, .5);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    outline: 2px solid transparent;
    outline-offset: 0;
}

  :global(.jsoneditor) :global(button:active) {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 234, 235, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(35, 35, 41, var(--tw-text-opacity));
}

  :global(.dark) :global(.jsoneditor) :global(button:active) {
    --tw-bg-opacity: 1;
    background-color: rgba(28, 28, 32, var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgba(172, 172, 175, var(--tw-text-opacity));
}

  :global(.jsoneditor) :global(.editor-container) {
    width: 85%;
    position: relative;
    border: none;
    line-height: 1.5;
    color: #669900;
    height: 100%;
    padding-left: 1.5rem;
  }

  :global(.jsoneditor) :global(.editor-container) :global(textarea) {
    caret-color: black;
  }

  :global(.dark) :global(.jsoneditor) :global(.editor-container) {
    color: #a6e22e;
  }

  :global(.dark) :global(.jsoneditor) :global(.editor-container) :global(.customscrollbar) {
    --scrollbarBG: #1c1c20;
  }

  :global(.dark) :global(.jsoneditor) :global(.editor-container) :global(textarea) {
    caret-color: white;
  }

  :global(.jsoneditor) :global(.editor-container) :global(pre) {
    counter-reset: line;
  }
  :global(.jsoneditor) :global(.editor-container) :global(pre) :global(code) {
    padding-left: 2.8rem;
    white-space: pre;
    counter-increment: line;
  }

  :global(.jsoneditor) :global(.editor-container) :global(pre) :global(code:before) {
    width: 1.5rem;
    padding-right: 1.5rem;
    left: 0;
    white-space: nowrap;
    position: absolute;
    -webkit-user-select: none;
    content: counter(line);
  }</style>
